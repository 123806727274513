<template>
  <div>
    <!-- <b-carousel
      controls
      indicators
    >
      <b-carousel-slide
        img-height="200"
        :img-src="require('@/assets/images/portal/baner.png')"
      />
    </b-carousel> -->
    <div
      class="prev mt-4"
    >
      <arq-previews-page />
    </div>
    <div
      v-if="!url"
      class="row match-height mx-4 card-arq mt-4"
    >

      <div
        v-for="card in cards"
        :key="card.id"
        class="col-xl-4 col-lg-4 col-md-6 col-sm-12 my-2"
      >
        <b-card
          :img-src="require('@/assets/images/banners/banner.jpg')"
          class="card-profile fondo "
          img-alt="Icono"
          img-top
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <feather-icon
                v-if="card.icon.includes('Icon')"
                class="my-2"
                size="42"
                :icon="card.icon"
              />
              <div v-else>
                <b-avatar
                  :src="card.icon"
                  size="114"
                  variant="light"
                  :alt="'img' + card.title"
                />
              </div>
            </div>
            <h4 class="">
              {{ card.title }}
            </h4>
          </div>
          <p
            class="text-center justify-center text-responsive w-75"
            style="position: relative; margin-left: auto; margin-right: auto;"
          >
            {{ card.description }}
          </p>
          <div class="row">
            <div
              class="col-md-12"
            >
              <center>
                <b-button
                  v-if="card.targetBlank"
                  target="blank"
                  class="bg-dark"
                  pill
                  :href="card.externalLink"
                >
                  Realizar transacción
                </b-button>
                <b-button
                  v-else
                  class="bg-dark"
                  pill
                  @click="irA(card.externalLink, card)"
                >
                  Realizar transacción
                </b-button>
              </center>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div v-else>
      <button @click="irA(null, null)">
        Volveif (localStorage.getItem('access_token_portal')) {
        console.log('Ya se encuentra logueado')
        this.$router.push(url)
        } else {
        console.log('No se encuentra logueado')
        if (selected.login_required.includes('1')) {
        console.log('Login')
        this.$bvModal.show('modal-login')
        } else {
        this.$router.push(url)
        }
        }  <br>
        <iframe
          width="1000"
          height="1000"
          :src="url"
          style="width: 100% height: 100%"
        />
      </button></div>
  </div>
</template>

<script>

import axios from 'axios'
// import qs from 'qs'

export default {
  name: 'ChildrenModules',
  data() {
    return {
      verMas: false,
      color: '#0076a3',
      colorIcon: '#0076a3',
      colorTextLink: '#fff',
      logo: 'Logo',
      url: null,
      cards: [],
    }
  },
  created() {
    this.getModulesChildren(this.$route.params.id)
  },
  methods: {
    irA(url, selected) {
      if (localStorage.getItem('access_token_portal')) {
        this.$router.push(url)
      } else if (selected.login_required.includes('1')) {
        this.$bvModal.show('modal-login')
      } else {
        this.$router.push(url)
      }
    },
    getModulesChildren(id) {
      axios
        .get(`/app/modules/children?idParent=${id}`)
        .then(res => {
          this.cards = res.data.list
        })
        .catch()
        .finally(() => {
        })
    },
  },
}
</script>

<style>
.card-arq .button-arq {
  background-color: rgb(0, 118, 163, 0.4) !important;
  border-color: rgb(0, 118, 163, 0.8) !important;
  background-color: rgb(0, 118, 163, 0.8) !important;
  border-color: rgb(0, 118, 163, 0.4) !important;
  color: #fff !important;
}
.card-arq p {
  width: 300px;
}
.card-arq {
  transition: transform 0.2s; /* Animation */
}
/* .card-arq:hover {
  background-color:rgb(0,118,163);
  color: rgb(226, 226, 226);

  height: 220px;
  border-radius: 15px;
  transform: scale(
    1.2
  );
}
.card-arq:hover h4 {
  color: white !important;
}*/
.card-arq .button-arq:hover {
  background-color: #0076a3 !important;
  border-color: #0076a3 !important;
  color: #fff !important;
}

.prev {
  position: relative;
  margin-left: 10em;
}

.desc {
  font-size: 12px;
}

.text-responsive {
font-size: calc(4px + 1vw) !important;
}

</style>
